.clexica-result p {
  text-align: right;
  direction: rtl;
  font-size: 1.25rem;
  line-height: 2rem;
}

.clexica-result p em {
  font-style: normal;
  font-weight: normal;
  color: red;
}

.text-button {
  height: auto !important;
  background-color: transparent;
  padding: 0px;

}

.text-button:hover {
  height: auto !important;
  background-color: transparent;
  padding: 0px;
  color: black;
  text-decoration: underline;
}

.classical-dictionary {
  position: relative;
  max-height: 300px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.classical-dictionary.expanded {
  max-height: none;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, white, transparent);
  pointer-events: none;
}

.expanded .gradient-overlay {
  display: none;
}

.expand-button {
  position: absolute;
  bottom: 0px;
  margin-right: -30px;
  right: 0;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  width: 60px;
  min-height: 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.center a {
  margin: 0 10px;
  text-decoration: none;
  color: #007bff;
}

.center a:hover {
  text-decoration: underline;
}