.search-input {
  height: 50px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 1.5rem;
  width: 200px;
  box-sizing: border-box;
}

.search-form {
  width: 50%;
}

.search-button {
  font-size: 1rem;
  width: 200px;
  margin-top:20px;
}

.search-button:disabled:hover {
  background-color: #ccc;
  color: #999;
  cursor: default;
}

.search-button:disabled {
  color:#999;
}

.normalization-container {
  margin-top:10px;
  direction: rtl;
}
