.additional-char-input {
  width: 30px !important;
  height: 30px;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0px !important;
  border: 1px solid #ccc;
}

.close-button {
  margin-left:10px !important;
}

.arabic-letter {
  font-size: 2rem;
}

.selected-char {
  color: red;
}

.additional-char {
  display: inline-block;
  margin: 5px 5px 5px 0;
  padding: 2px 5px;
  border-radius: 3px;
}

.char-options {
  border-radius: 20px;
  /* margin-top: 20px; */
}

.char-options h4 {
  margin: 0 0 5px;
}

.add-char-container {
  height: 75px;
  margin-left: 5px !important;
}

.add-char-button {
  display: block;
  font-size: 1.5rem;
  min-height: 20px !important;
}

.add-char-button:hover {
  background-color: transparent;
  color: red;
}

.alternative-chars {
  justify-content: center;
  align-items: center;
  flex-direction: row;
  direction: rtl;
}

.alternative-chars label {
  width: 25px;
  height: 75px;
  font-size: 1.5rem;
  vertical-align: middle;
  cursor: pointer;
}

.alternative-chars label input[type=checkbox] {
  cursor: pointer;
}

.alternative-chars label:hover {
  background-color: #e2e2e2;
}

.char-options input[type="text"] {
  margin: 5px 0;
}

.char-options button {
  margin: 5px 5px 5px 0;
}