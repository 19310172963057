html {
  font-size:15px !important;

}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  margin:0px;
}

div, nav{
  box-sizing: border-box;
}

a {
  font-size:1rem !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin:0px !important;
  padding: 0;
  list-style-type: disc;
  text-align: left;
  padding-left: 20px;
}

button {
  background-color: #ccc;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  min-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
}

button:hover {
  background-color: #333;
  color: white;
}

p {
  line-height:1.5rem;
}

.m0 {
  margin: 0px !important;
}

.m-auto {
  margin:auto;
}

.center {
  text-align: center;
}

.p10 {
  padding: 10px;
}

.p5 {
  padding: 5px;
}

.br5 {
  border-radius: 5px;
}

.flex {
  display: flex;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;700&display=swap');

.arabic-font {
    font-family: 'Noto Sans Arabic';
}

.large-font {
  font-size:1.5rem;
}

.med-font {
  font-size:1.2rem;
  line-height:1rem;
}

.main-link {
  color: black;
  font-size:2rem !important;
  text-decoration: none;
}

.container {
  margin: auto;
  width: 100%;
  max-width: 1200px;
}

.main {
  text-align: center;
  padding-bottom:100px;
}

.link-button {
  background-color: transparent;
  border:0px;
  cursor: pointer;
}

.link-button:hover {
text-decoration: underline;
}

.navbar {
  overflow: hidden;
  margin-bottom:20px;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  justify-content: center;
}

.nav-links li {
  float: left;
  padding:0px 10px 0px 10px
}

.nav-links li a {
  display: block;
  color: black;
  text-align: center;
  text-decoration: none;
}

.nav-links li a:hover {
  color: black;
  text-decoration: underline;
}

