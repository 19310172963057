header {
    width: 100%;
  }
  
  .header-text {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .header-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }

  nav {
    text-align: center;
    padding-top: 20px;
  }
  
  nav ul {
    justify-content: center;
  }
  
  nav ul a {
    text-decoration: none;
    color: black;
  }
  
  nav ul a:hover {
    text-decoration: underline;
  }
  
  nav li {
    float: left;
    padding: 0px 10px;
  }
  
  footer {
    margin:20px;
    margin-top: auto;
    flex: 0 0 125px;
    max-width: 100%;
    height: 128px;
  }
  
  .div-footer {
    text-align: center;
    padding-top: 12.5px;
    width: 100%;
    margin: auto;
    height: 125px;
    position: relative;
  }
  
  .footer-link-container {
    text-align: center;
    width: 100%;
    padding-bottom:20px;
  }
  
  .footer-link-container a {
    margin: 20px;
    text-decoration: none;
  }
  
  .footer-link-container img {
    display: inline;
  }
  
  .footer-link-container a:hover {
    text-decoration: underline;
  }
  