.about {
    width: 100%;
    max-width: 800px;
    text-align: left;
}

.about img {
    display: block;
    margin: auto; 
}

@media (max-width: 768px) {
    .about {
        width: 90%;
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .about {
        width: 100%;
        padding: 20px;
    }
}

.example-image {
    width:100%;
    max-width:593px;
}