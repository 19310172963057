.search-result {
  align-items: stretch;
  width: 100%;
}

.search-result:hover {
  background-color: rgba(240, 248, 255, 0.432);
}

.word-result {
  flex: 0 0 20%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
}

.word-result:first-child {
  width: 100%;
}

.alphabetical-result {
  margin-top: 20px;
  width: 100%;
  font-size: 1rem;
}

.root-result-list {
  height: 100%;
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
}

.root-result {
  flex-direction: column;
  flex: 1 1 calc(33% - 5px);
  max-width: calc(33% - 5px);
  padding: 0px 15px 15px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
}

.result-link-container {
  margin-top: auto;
}

.result-link {
  font-size: 1.25rem;
  color: rgb(25, 110, 39);
  font-weight: bold;
  text-decoration: none;
}

.result-link:hover {
  color: black;
}

.root-display {
  font-weight: bold;
}

#results {
  margin-top: 10px;
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 768px) {
  #results {
    width: 90%;
    padding: 5px;
  }

  .root-result {
    max-width: 100%;
  }

}

@media (max-width: 480px) {
  #results {
    width: 100%;
    padding: 2px;
  }

}

.search-result:last-child {
  border-bottom: none;
}